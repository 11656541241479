// @flow
import { asyncAction, syncAction } from 'lib/action';

export const createApprovalAction = asyncAction('APPROVALS/CREATE');
export const removeApprovalAction = asyncAction('APPROVALS/REMOVE');
export const updateApprovalAction = asyncAction('APPROVALS/UPDATE');
export const changeOrderApprovalAction = asyncAction('APPROVALS/CHANGE_ORDER');
export const approveApprovalAction = asyncAction('APPROVALS/APPROVE');
export const submitApprovalAction = asyncAction('APPROVALS/SUBMIT');
export const getApprovalRejectReasonsAction = asyncAction('APPROVALS/GET_REJECT_REASONS');
export const rejectApprovalAction = asyncAction('APPROVALS/REJECT');
export const getApprovalApproveReasonsAction = asyncAction('APPROVALS/GET_APPROVE_REASONS');
export const updateApprovalApproveReasonsAction = asyncAction('APPROVALS/UPDATE_APPROVE_REASONS');
export const changeBulkApprovalStatusAction = asyncAction('APPROVALS/CHANGE_BULK_APPROVAL_STATUS');
export const getApprovalGroupsAction = asyncAction('APPROVALS/GET_APPROVAL_GROUPS');
export const clearApprovalGroupsStateAction = syncAction('APPROVALS/CLEAR_APPROVAL_GROUPS_STATE');
export const createApprovalsGroupAction = asyncAction('APPROVALS/CREATE_APPROVALS_GROUP');
export const deleteApprovalsGroupAction = asyncAction('APPROVALS/DELETE_APPROVALS_GROUP');
export const updateApprovalsGroupAction = asyncAction('APPROVALS/UPDATE_APPROVALS_GROUP');
export const bulkCreateApprovalsAction = asyncAction('APPROVALS/BULK_CREATE_APPROVALS');
export const bulkApprovalReplaceAction = asyncAction('APPROVALS/BULK_APPROVAL_REPLACE');
export const getApproversAction = asyncAction('APPROVALS/GET_APPROVERS');
export const createApproverAction = asyncAction('APPROVALS/CREATE_APPROVER');
export const deleteApproverAction = asyncAction('APPROVALS/DELETE_APPROVER');
export const getAdvancedApprovalGroupsAction = asyncAction('APPROVALS/GET_ADVANCED_APPROVAL_GROUPS');
export const createAdvancedApprovalsGroupAction = asyncAction('APPROVALS/CREATE_ADVANCED_APPROVALS_GROUP');
export const deleteAdvancedApprovalsGroupAction = asyncAction('APPROVALS/DELETE_ADVANCED_APPROVALS_GROUP');
export const updateAdvancedApprovalsGroupAction = asyncAction('APPROVALS/UPDATE_ADVANCED_APPROVALS_GROUP');
