// @flow
import React from 'react';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Approver from 'pages/components/Approver';

import Tooltip from 'components/mui/Tooltip';
import AvatarGroup from '@mui/material/AvatarGroup';

import type { AdvancedApprovalGroupsLevelType } from 'domain/approvals/types';

type TLevelsCell = {
  value: Array<AdvancedApprovalGroupsLevelType>,
};

const LevelsCell: React$StatelessFunctionalComponent<TLevelsCell> = ({ value }) => (
  <Stack direction="row" gap={1} flexWrap="wrap">
    {value.map(({ approvers }, i) => {
      const isSingleApprover = approvers.length === 1;
      const avatar = isSingleApprover ? <Avatar alt={approvers[0].fullName} src={approvers[0].picture} /> : null;
      const label = isSingleApprover ? (
        approvers[0].fullName
      ) : (
        <AvatarGroup
          max={3}
          sx={{
            mx: -1,
            '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
            '& .MuiAvatar-root:nth-of-type(1)': {
              zIndex: approvers.length,
            },
          }}
        >
          {approvers.map((a, j) => (
            <Tooltip key={a.id} t={a.fullName}>
              <Avatar src={a.picture} alt={a.fullName} sx={{ zIndex: j }} />
            </Tooltip>
          ))}
        </AvatarGroup>
      );
      return <Approver key={i} avatar={avatar} label={label} variant="outlined" hasArrow={i + 1 !== value.length} />;
    })}
  </Stack>
);

export default LevelsCell;
