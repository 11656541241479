// @flow
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material';

import useApprovals, { filterOptions, messages } from 'hooks/approval/useApprovals';

import FormControl from '@mui/material/FormControl';
import Autocomplete from 'pages/components/ApproversAutocomplete';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import ListSubheader from '@mui/material/ListSubheader';
import SortableList from 'pages/document/DocumentTopPanel/approval/dnd/SortableList';
import Stack from '@mui/material/Stack';
import Approver from 'pages/components/Approver';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextFieldBase from 'components/mui/Form/TextField/TextFieldBase';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Tooltip from 'components/mui/Tooltip';

import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

import { alpha } from '@mui/material/styles';
import { AntSwitch, SMenuItem, MenuList, AvatarGroup } from './StyledComponents';

import type { AdvancedApprovalGroupsLevelType } from 'domain/approvals/types';

import { Fields } from 'pages/configurations/company/pages/approvals/pages/advancedGroups/helpers';
import { MAX_AVATARS_FOR_GROUPS } from './constants';
import { LEVEL_FLOW_TYPE } from 'domain/approvals/constatns';

import elements from 'components/elements';

type TLevelItem = {
  levelData: AdvancedApprovalGroupsLevelType,
  isSubmitting: boolean,
  touched: boolean,
  setTouched: (data: any) => void,
  errors: any,
  handleLevelChange: (i: number, name: string, values: any) => void,
  removeLevel: (i: number) => void,
  i: number,
  canBeDelete: boolean,
};

const RightIcon = (props) => <ArrowRightAltOutlinedIcon fontSize="4px" {...props} />;

const groupsOrder = ['clients', 'accountants'];

const useStyles = makeStyles((theme) =>
  createStyles({
    bodyTags: {
      bodyTags: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 1 100%',
      },
    },
    approvalDraggableElem: {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);

const LevelItem: React$StatelessFunctionalComponent<TLevelItem> = ({
  levelData,
  isSubmitting,
  setTouched,
  touched,
  errors,
  handleLevelChange,
  removeLevel,
  i,
  canBeDelete,
}) => {
  const { formatMessage } = useIntl();
  const [focusedApproversContainer, setFocusedApproversContainer] = useState(false);
  const [previewTags, setPreviewTags] = useState([]);
  const approversContainerRef = useRef(null);

  const theme = useTheme();
  const classes = useStyles();

  const { approvers, value: approversValue } = useApprovals(levelData[Fields.approvers], groupsOrder);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: levelData.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const handleAutocompleteChange = useCallback(
    (name) => (e, value) => {
      const values = value.reduce((acc, option) => {
        if (option?.approvers) {
          option.approvers.forEach((approver) => {
            if (!acc.some((item) => item.id === approver.id)) {
              acc.push(approver);
            }
          });
        } else {
          acc.push(option);
        }
        return acc;
      }, []);
      handleLevelChange(i, name, values);
      if (!touched[Fields.levels][i][Fields.minApprovers] || levelData[Fields.minApprovers] > values.length) {
        handleLevelChange(i, Fields.minApprovers, values.length);
      }
    },
    [handleLevelChange, i, touched, levelData],
  );

  const handleDelete = useCallback(
    (id) => {
      handleAutocompleteChange([Fields.approvers])(
        undefined,
        levelData[Fields.approvers].filter((i) => i.id !== id),
      );
    },
    [handleAutocompleteChange, levelData],
  );

  const handleFocusApproversField = useCallback(() => {
    setFocusedApproversContainer(true);
  }, []);

  const onBlur = useCallback(
    (e) => {
      if (e.target.name) {
        const updatedLevels = [...touched[Fields.levels]];
        updatedLevels[i][e.target.name] = true;
        setTouched({ ...touched, [Fields.levels]: updatedLevels });
      }
      setFocusedApproversContainer(false);
    },
    [setTouched, i, touched],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      handleLevelChange(i, Fields.approvers, arrayMove(levelData[Fields.approvers], oldIndex, newIndex));
    },
    [levelData, handleLevelChange, i],
  );

  const tileSize = useMemo(() => [...approversValue].splice(previewTags.length).length, [previewTags, approversValue]);

  const handleFlowTypeClick = useCallback(
    (e) => {
      e.preventDefault();
      handleLevelChange(
        i,
        Fields.flowType,
        levelData[Fields.flowType] === LEVEL_FLOW_TYPE.SEQUENTIAL
          ? LEVEL_FLOW_TYPE.PARALLEL
          : LEVEL_FLOW_TYPE.SEQUENTIAL,
      );
    },
    [levelData, handleLevelChange, i],
  );

  const handleClickSeqFlow = useCallback(() => {
    if (levelData[Fields.flowType] !== LEVEL_FLOW_TYPE.SEQUENTIAL) {
      handleLevelChange(i, Fields.flowType, LEVEL_FLOW_TYPE.SEQUENTIAL);
    }
  }, [levelData, handleLevelChange, i]);

  const handleClickParallelFlow = useCallback(() => {
    if (levelData[Fields.flowType] !== LEVEL_FLOW_TYPE.PARALLEL) {
      handleLevelChange(i, Fields.flowType, LEVEL_FLOW_TYPE.PARALLEL);
    }
  }, [levelData, handleLevelChange, i]);

  useEffect(() => {
    const tags = [];
    if (!focusedApproversContainer && approversContainerRef.current) {
      const wrapperWidth = approversContainerRef.current.getBoundingClientRect().width;
      let tw = 40; // approximate chip width
      approversValue.forEach((tag) => {
        const string = tag.fullName;
        tw += string.length * 6 + 48;
        if (tw < wrapperWidth) {
          tags.push(tag);
        }
      });
    }
    setPreviewTags(tags);
  }, [approversValue, focusedApproversContainer, approversContainerRef]);

  return (
    <Stack direction="row" alignItems="flex-start" gap={2} my={2} ref={setNodeRef} style={style} {...attributes}>
      {canBeDelete && (
        <DragIndicatorOutlinedIcon
          {...listeners}
          sx={{ color: 'grey.600', cursor: isDragging ? 'grabbing' : 'grab', mt: 2, mr: -2 }}
        />
      )}
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          required
          margin="normal"
          disabled={isSubmitting}
          data-element={elements.popup.advancedApprovalGroups.selectApprovers}
          noOptionsText={formatMessage({
            id: 'document.approval.noSuggestions',
            defaultMessage: 'No company users available',
          })}
          options={approvers}
          groupBy={(option) => option.group}
          value={approversValue}
          onChange={handleAutocompleteChange(Fields.approvers)}
          onBlur={onBlur}
          onFocus={handleFocusApproversField}
          getOptionLabel={(o) => o.group_id || o.id}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                sx: { flexWrap: `${focusedApproversContainer ? 'wrap' : 'nowrap'} !important` },
              }}
              name={Fields.approvers}
              error={Boolean(
                errors[Fields.levels][i]?.[Fields.approvers] && touched[Fields.levels][i]?.[Fields.approvers],
              )}
              label={formatMessage({
                id: 'configurations.company.approvals.advancedGroups.form.approvers.label',
                defaultMessage: 'Approvers',
              })}
            />
          )}
          renderOption={(params, option, state) =>
            option.group_id ? (
              <SMenuItem
                {...params}
                selected={state.selected}
                disabled={state.disabled}
                data-element={elements.popup.advancedApprovalGroups.groupOption}
              >
                <Stack alignItems="center" direction="row" gap={1}>
                  <AvatarGroup max={MAX_AVATARS_FOR_GROUPS}>
                    {option.approvers.map((a, i) => (
                      <Tooltip key={a.id} t={a.fullName}>
                        <Avatar src={a.picture} alt={a.fullName} sx={{ zIndex: i }} />
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                  <ListItemText sx={{ textWrap: 'wrap' }}>{option.group_title}</ListItemText>
                </Stack>
              </SMenuItem>
            ) : (
              <SMenuItem
                {...params}
                selected={state.selected}
                data-element={elements.popup.advancedApprovalGroups.option}
              >
                <ListItemText sx={{ mr: 1 }}>
                  <Stack alignItems="center" direction="row" gap={1}>
                    <Avatar alt={option.fullName} src={option.picture} sx={{ width: 24, height: 24 }} />
                    {option.fullName}
                    <br />({option.tag})
                  </Stack>
                </ListItemText>
                {state.selected && (
                  <ListItemIcon>
                    <Check sx={{ color: 'secondary.main' }} />
                  </ListItemIcon>
                )}
              </SMenuItem>
            )
          }
          renderGroup={(params) => (
            <Box key={params.key} data-element={`${elements.popup.advancedApprovalGroups.listOf}.${params.group}`}>
              <ListSubheader
                sx={{
                  top: '-8px',
                  lineHeight: '40px',
                  color: alpha(theme.palette.common.black, 0.38),
                  zIndex: MAX_AVATARS_FOR_GROUPS + 1,
                }}
              >
                {formatMessage(messages[params.group], { count: params.children.length })}
              </ListSubheader>
              <MenuList>{params.children}</MenuList>
            </Box>
          )}
          renderTags={(selected) =>
            focusedApproversContainer ? (
              <SortableList
                items={selected}
                className={classes.bodyTags}
                idKey="id"
                onSortOver={() => {}}
                onSortEnd={onSortEnd}
                Element={(props) => (
                  <Stack
                    gap={1}
                    flexWrap="wrap"
                    direction="row"
                    {...props}
                    data-element={elements.popup.advancedApprovalGroups.selectedApproversContainer}
                  >
                    {props.children}
                  </Stack>
                )}
                helperClass={classes.approvalDraggableElem}
              >
                {({ fullName, picture, id, className, style }) => (
                  <Approver
                    avatar={<Avatar alt={fullName} src={picture} />}
                    label={fullName}
                    variant="outlined"
                    onDelete={() => {
                      handleDelete(id);
                    }}
                    className={className}
                    style={style}
                    hasArrow={false}
                  />
                )}
              </SortableList>
            ) : (
              <Stack ref={approversContainerRef} direction="row" gap={1} flexWrap="nowrap" width="100%">
                {previewTags.map(({ picture, fullName, id }) => (
                  <Approver
                    key={id}
                    avatar={<Avatar alt={fullName} src={picture} />}
                    label={fullName}
                    variant="outlined"
                    hasArrow={false}
                  />
                ))}
                {!!tileSize && <Chip label={`+${tileSize}`} sx={{ backgroundColor: 'common.white' }} />}
              </Stack>
            )
          }
        />
        {!!errors[Fields.levels][i]?.[Fields.approvers] && touched[Fields.levels][i]?.[Fields.approvers] && (
          <FormHelperText data-element={elements.popup.advancedApprovalGroups.selectApproversValidationError} error>
            {errors[Fields.levels][i][Fields.approvers]}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl sx={{ minWidth: 140 }}>
        <InputLabel required>
          {formatMessage({
            id: 'configurations.company.approvals.advancedGroups.form.minApprovers.placeholder',
            defaultMessage: 'Min Approvers',
          })}
        </InputLabel>
        <Select
          name={Fields.minApprovers}
          label={formatMessage({
            id: 'configurations.company.approvals.advancedGroups.form.minApprovers.placeholder',
            defaultMessage: 'Min Approvers',
          })}
          value={levelData[Fields.minApprovers]}
          onChange={(e) => {
            handleLevelChange(i, Fields.minApprovers, e.target.value);
            onBlur(e);
          }}
          data-element={elements.popup.advancedApprovalGroups.minApprovers}
        >
          {[...Array(approversValue.length || 1).keys()].map((n) => (
            <MenuItem key={n + 1} value={n + 1} data-element={elements.popup.advancedApprovalGroups.minApproversOption}>
              {approversValue.length === n + 1 || !approversValue.length ? 'All' : n + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 140 }}>
        <TextFieldBase
          required
          type="int"
          error={Boolean(errors[Fields.levels][i]?.[Fields.minAmount])}
          value={levelData[Fields.minAmount]}
          name={Fields.minAmount}
          onChange={(value) => handleLevelChange(i, Fields.minAmount, value)}
          onBlur={onBlur}
          label={formatMessage({
            id: 'configurations.company.approvals.advancedGroups.form.minAmount.placeholder',
            defaultMessage: 'Min Amount',
          })}
          data-element={elements.popup.advancedApprovalGroups.minAmount}
        />
        {!!errors[Fields.levels][i]?.[Fields.minAmount] && (
          <FormHelperText data-element={elements.popup.advancedApprovalGroups.minAmountValidationError} error>
            {errors[Fields.levels][i][Fields.minAmount]}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl sx={{ minWidth: 220, cursor: 'pointer', mt: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            component="div"
            onClick={handleClickSeqFlow}
            variant="body1"
            color={levelData[Fields.flowType] === LEVEL_FLOW_TYPE.SEQUENTIAL ? 'primary' : 'inherit'}
            data-element={elements.popup.advancedApprovalGroups.sequential}
          >
            <Stack direction="row" alignItems="center">
              <RightIcon />
              <RightIcon />
              {formatMessage({
                id: 'configurations.company.approvals.advancedGroups.form.flow.seq.placeholder',
                defaultMessage: 'Sequential',
              })}
            </Stack>
          </Typography>
          <AntSwitch
            checked={levelData[Fields.flowType] === LEVEL_FLOW_TYPE.PARALLEL}
            onClick={handleFlowTypeClick}
            data-element={elements.popup.advancedApprovalGroups.flowTypeSwitcher}
          />
          <Typography
            component="div"
            onClick={handleClickParallelFlow}
            variant="body1"
            color={levelData[Fields.flowType] === LEVEL_FLOW_TYPE.PARALLEL ? 'primary' : 'inherit'}
            data-element={elements.popup.advancedApprovalGroups.parallel}
          >
            <Stack direction="row" alignItems="center">
              <Stack alignItems="center">
                <RightIcon />
                <RightIcon sx={{ mt: -1 }} />
              </Stack>
              {formatMessage({
                id: 'configurations.company.approvals.advancedGroups.form.flow.parallel.placeholder',
                defaultMessage: 'Parallel',
              })}
            </Stack>
          </Typography>
        </Stack>
      </FormControl>
      <IconButton
        color="error"
        onClick={() => removeLevel(i)}
        disabled={!canBeDelete}
        sx={{ mt: 1 }}
        data-element={elements.popup.advancedApprovalGroups.deleteLevelBtn}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Stack>
  );
};

export default LevelItem;
